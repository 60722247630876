<template>
  <v-row>
    <v-col cols="12">
      <h3>Dirección</h3>
    </v-col>

    <v-col cols="12" sm="6">
      <b>Calle:</b> {{ address.street }}
    </v-col>

    <v-col cols="12" sm="6">
      <b>Número Exterior:</b> {{ address.outer_number }}
    </v-col>

    <v-col cols="12" sm="6">
      <b>Número Interior:</b> {{ address.inner_number || '---' }}
    </v-col>

    <v-col cols="12" sm="6">
      <b>Colonia:</b> {{ address.suburb }}
    </v-col>

    <v-col cols="12" sm="6">
      <b>Municipio/Alcaldía:</b> {{ address.municipality }}
    </v-col>

    <v-col cols="12" sm="6">
      <b>Ciudad:</b> {{ address.city }}
    </v-col>

    <v-col cols="12" sm="6">
      <b>Estado:</b> {{ address.state }}
    </v-col>

    <v-col cols="12" sm="6">
      <b>País:</b> {{ address.country_display }}
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'AddressDetail',

  props: {
    address: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
