<template>
  <v-card>
    <v-card-title>
      <v-spacer></v-spacer>
      <v-spacer></v-spacer>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Buscar"
        clearable
        hide-details
        single-line
      />
    </v-card-title>

    <v-data-table
      :headers="headers"
      :items="tracking"
      :search="search"
    >
      <template v-slot:item.created_at="{ value }">
        {{ value | date({ time: true }) }}
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
export default {
  name: 'TrackingList',

  props: {
    tracking: {
      type: Array,
      required: true,
    },
  },

  data: () => ({
    headers: [
      { text: 'Fecha', value: 'created_at' },
      { text: 'Operación', value: 'operation.name' },
      { text: 'Operador', value: 'operator.full_name' },
      { text: 'Nota', value: 'note' },
    ],
    search: '',
  }),
};
</script>

<style lang="scss" scoped>
.total-row {
  background-color: rgba(0, 0, 0, 0.2);
}
.total-row:hover {
  background-color: rgba(0, 0, 0, 0.25) !important;
}
</style>
