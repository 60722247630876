<template>
  <section>
    <v-row>
      <v-col>
        <v-breadcrumbs :items="breadcrumbs" class="ma-0 pa-0" />
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" lg="4">
        <v-row>
          <v-col class="d-flex justify-space-between flex-wrap">
            <h1 class="d-flex align-center">
              <span>{{ order.number | uppercase }}</span>
              <v-divider
                class="mx-2"
                vertical
              />
              <span>Seguimiento</span>
            </h1>

            <v-btn
              color="info"
              :to="{ name: 'order-detail', params: { uuid } }"
            >
              <v-icon left>mdi-eye</v-icon>
              Detalle
            </v-btn>
          </v-col>
        </v-row>

        <OrderGeneral :order="order" />
        <CustomerGeneral
          class="mt-3"
          :customer="order.customer"
        />
      </v-col>

      <v-col cols="12" lg="8">
        <v-card
          v-if="showOperationAddition"
          class="mb-10"
        >
          <v-card-title>
            Nueva operación
          </v-card-title>

          <Form @submit="saveOperation" ref="form">
            <v-container>
              <v-row>
                <v-col cols="12" sm="6" lg="4">
                  <SelectField
                    v-model="form.operation_id"
                    :items="availableOperations"
                    item-text="name"
                    item-value="id"
                    label="Operación"
                    prepend-icon="mdi-format-list-checks"
                    rules="required"
                  />
                </v-col>

                <v-col cols="12" sm="6" lg="8">
                  <TextAreaField
                    v-model="form.note"
                    label="Nota"
                    prepend-icon="mdi-comment"
                    rows="1"
                    auto-grow
                  />
                </v-col>

                <v-col cols="12" class="text-center">
                  <v-btn color="primary" type="submit">
                    <v-icon left>mdi-content-save</v-icon>
                    Guardar
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </Form>
        </v-card>

        <h3>Operaciones</h3>
        <TrackingList :tracking="tracking" />
      </v-col>
    </v-row>
    <!-- <v-divider class="my-5"></v-divider> -->
  </section>
</template>

<script>
// API
import OrderAPI from '@/api/order';

// Components
import CustomerGeneral from './components/CustomerGeneral.vue';
import OrderGeneral from './components/OrderGeneral.vue';
import TrackingList from './components/TrackingList.vue';

export default {
  name: 'OrderDetail',

  props: {
    uuid: {
      type: String,
      required: true,
    },
  },

  components: {
    CustomerGeneral,
    OrderGeneral,
    TrackingList,
  },

  data: () => ({
    form: {
      operation_id: null,
      note: null,
    },
    order: {
      created_at: new Date(),
      customer: {},
      products: [],
      updated_at: new Date(),
    },
    availableOperations: [],
    tracking: [],
  }),

  computed: {
    breadcrumbs() {
      const name = this.order.number || '';
      return [
        {
          text: 'Órdenes',
          disabled: false,
          exact: true,
          to: { name: 'order-list' },
        },
        {
          text: name.toUpperCase(),
          disabled: false,
          exact: true,
          to: { name: 'order-detail', params: { uuid: this.uuid } },
        },
        {
          text: 'Seguimiento',
          disabled: true,
        },
      ];
    },

    showOperationAddition() {
      if (!this.order.status) return false;

      return !['Entregada', 'Cancelada'].includes(this.order.status);
    },
  },

  async created() {
    this.api = new OrderAPI();
    await this.updateView();
  },

  methods: {
    async updateView() {
      await this.detail();
      await this.getTracking();
      await this.getAvailableOperations();

      if (this.$refs.form) {
        this.$refs.form.reset();
      }
    },

    async detail() {
      this.$store.commit('showLoader');
      try {
        this.order = await this.api.detail(this.uuid);
      } catch (error) {
        this.$store.dispatch('notification/notifyError');
      }
      this.$store.commit('hideLoader');
    },

    async getAvailableOperations() {
      this.$store.commit('showLoader');
      try {
        this.availableOperations = await this.api.availableOperations(this.uuid);
      } catch (error) {
        this.$store.dispatch('notification/notifyError');
      }
      this.$store.commit('hideLoader');
    },

    async getTracking() {
      this.$store.commit('showLoader');
      try {
        this.tracking = await this.api.tracking(this.uuid);
      } catch (error) {
        this.$store.dispatch('notification/notifyError');
      }
      this.$store.commit('hideLoader');
    },

    async saveOperation() {
      this.$store.commit('showLoader');
      try {
        await this.api.saveOperation(this.uuid, this.form);
        await this.updateView();
      } catch (error) {
        this.$store.dispatch('notification/notifyError');
      }
      this.form = {
        operation_id: null,
        note: null,
      };
      this.$store.commit('hideLoader');
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
