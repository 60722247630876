<template>
  <v-card>
    <v-card-title>
      <h3>Orden</h3>
    </v-card-title>

    <v-container>
      <v-row>
        <v-col cols="12">
          <b>Número:</b> {{ order.number }}
        </v-col>

        <v-col cols="12">
          <b>Estatus:</b> {{ order.status }}
        </v-col>

        <v-col cols="12">
          <b>Número de productos:</b> {{ order.products.length }}
        </v-col>

        <v-col cols="12">
          <b>Total:</b> {{ order.total | currency }}
        </v-col>

        <v-col cols="12">
          <b>Creación:</b> {{ order.created_at | date({ time: true }) }}
        </v-col>

        <v-col cols="12">
          <b>Modificación:</b> {{ order.updated_at | date({ time: true }) }}
        </v-col>

        <v-col cols="12" v-if="order.assignation">
          <b>Fecha y hora de recolección:</b>
          <br>
          {{ order.assignation.date | date }}
          {{ order.assignation.time_start | hour }} -
          {{ order.assignation.time_end | hour }} hrs
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
export default {
  name: 'OrderGeneral',

  props: {
    order: {
      type: Object,
      required: true,
    },
  },

  filters: {
    hour(value) {
      return value.slice(0, -3);
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
