// API
import BaseAPI from './base';

/**
 * Implement Order API
 */
export default class OrderAPI extends BaseAPI {
  constructor() {
    super('orders');
  }

  /**
   * Get all operations made on order.
   */
  tracking(id, params = {}) {
    return this.request.get(`${this.baseURI}/${id}/tracking`, { params });
  }

  /**
   * Get available operations for order.
   */
  availableOperations(id, params = {}) {
    return this.request.get(`${this.baseURI}/${id}/available-operations`, { params });
  }

  /**
   * Get operation catalogue.
   */
  operations(params = {}) {
    return this.request.get(`${this.baseURI}/operations`, { params });
  }

  /**
   * Get Mercado Pago preference ID for checkout button.
   */
  getPreferenceId(data) {
    return this.request.post(`${this.baseURI}/preference`, data);
  }

  /**
   * Process payment.
   */
  processPayment(data) {
    return this.request.post(`${this.baseURI}/process-payment`, data);
  }

  /**
   * Save operation for order's tracking.
   */
  saveOperation(id, data) {
    return this.request.post(`${this.baseURI}/${id}/save-operation`, data);
  }

  /**
   * Get order report.
   */
  orderReport(params = {}) {
    return this.request.get(`${this.baseURI}/order-report`, { params });
  }

  /**
   * Get order report.
   */
  report(params = {}) {
    return this.request.get(`${this.baseURI}/report`, { params });
  }

  /**
   * Get order summary.
   */
  summary(params = {}) {
    return this.request.get(`${this.baseURI}/summary`, { params });
  }
}
