<template>
  <v-card>
    <v-card-title>
      <h3>Cliente</h3>
    </v-card-title>

    <v-container>
      <v-row>
        <v-col cols="12">
          <b>Nombre:</b> {{ customer.full_name }}
        </v-col>

        <v-col cols="12">
          <b>Celular:</b> {{ customer.mobile_phone }}
        </v-col>

        <v-col cols="12">
          <b>Correo electrónico:</b> {{ customer.email }}
        </v-col>
      </v-row>

      <v-divider class="my-2"></v-divider>

      <AddressDetail
        v-if="customer.address"
        :address="customer.address"
      />
    </v-container>
  </v-card>
</template>

<script>
// Components
import AddressDetail from '@/components/address/Detail.vue';

export default {
  name: 'CustomerGeneral',

  props: {
    customer: {
      type: Object,
      required: true,
    },
  },

  components: {
    AddressDetail,
  },
};
</script>

<style lang="scss" scoped>

</style>
